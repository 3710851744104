import { motion } from "framer-motion";
import { FaLightbulb } from 'react-icons/fa';

import Container from "./Container.jsx";
import { useInView } from 'framer-motion';

import { useRef } from 'react';

export default function HeroSection() {

  const ref = useRef(null);

  const inView = useInView(ref, { once: false });


  return (

    <div className="relative" id="home">

      <div aria-hidden="true" className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40">

        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-primary"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-primary to-primary"></div>

      </div>

      <Container>
        <div className="relative pt-36 ml-auto" ref={ref}>

          <motion.div

            className="lg:w-2/3 text-center mx-auto"

            initial={{ opacity: 0, y: 20 }}

            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 1, ease: "easeInOut" }}

          >

            <motion.h1

              className="text-gray-900 font-bold text-5xl md:text-6xl xl:text-7xl"

              initial={{ opacity: 0, y: 20 }}

              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}

              transition={{ duration: 0.8, ease: "easeInOut" }}

            >

              We incubate and invest in 

              <span className="text-primary"> your ideas </span>
        

              <motion.span

                className="inline-block"

                initial={{ opacity: 1, rotate: 25 }}

                animate={{ opacity: [1, 0.6, 1] }}

                transition={{

                  repeat: Infinity,

                  duration: 0.8,

                  repeatType: "reverse",

                }}

              >
                <FaLightbulb className="text-yellow-400" />
              </motion.span>
            </motion.h1>


            <motion.p
              className="mt-8 text-gray-700 text-center text-l"

              initial={{ opacity: 0, y: 20 }}

              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}

              transition={{ duration: 1.2, ease: "easeInOut" }}
            >

              We believe investing at the earliest stage is the biggest advantage our people can bring to your company. 

              Whether it’s support in finding product-market-fit, building out your sales and marketing capabilities, 
              understanding how to grow your team or introductions to our network of operators and investors, 

              we provide you with a carefully curated core team to help you supercharge your vision.
            </motion.p>


            <motion.div

              className="mt-16 flex flex-wrap justify-center gap-y-4 gap-x-6"

              initial={{ opacity: 0, y: 20 }}

              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}

              transition={{ delay: 0.5, duration: 1, ease: "easeInOut" }}

            >

              <motion.a

                href="mailto:info@aloftincubation.com"

                className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"

                initial={{ opacity: 0, y: 20 }}

                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}

                transition={{ duration: 1, ease: "easeInOut" }}
                whileHover={{ scale: 1.05 }}

                whileTap={{ scale: 0.95 }}

              >

                <span className="relative text-base font-semibold text-white">Contact Us</span>
              </motion.a>

            </motion.div>
          </motion.div>

        </div>

      </Container>
    </div>
  );

}
