import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Container from "./Container.jsx";
import one from "./Assets/icons/number-one.png";
import two from "./Assets/icons/number-2.png";
import three from "./Assets/icons/number-3.png";
import four from "./Assets/icons/number-four.png";

const Features = () => {
    const cardRefs = useRef([]);
    const [isVisible, setIsVisible] = React.useState(Array(4).fill(false));

    const handleScroll = (entries) => {
        entries.forEach(entry => {
            const index = Number(entry.target.dataset.index);
            if (entry.isIntersecting) {
                setIsVisible(prev => {
                    const newVisibility = [...prev];
                    newVisibility[index] = true;
                    return newVisibility;
                });
            } else {
                setIsVisible(prev => {
                    const newVisibility = [...prev];
                    newVisibility[index] = false;
                    return newVisibility;
                });
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleScroll, {
            threshold: 0.1
        });

        cardRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div id="features">
            <Container>
                <div className="md:w-2/3 lg:w-1/2 mx-auto text-center">
                    <motion.h2
                        className="my-8 text-2xl font-bold text-gray-700 md:text-4xl"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeInOut" }}
                    >
                        Are You Eligible?
                    </motion.h2>
                    <motion.p
                        className="text-gray-600"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeInOut", delay: 0.2 }}
                    >
                        Before applying, ensure your business aligns with our focus on growth, innovation, and market potential. Your business should meet the following criteria:
                    </motion.p>
                </div>
                <div className="mt-16 grid divide-x divide-y divide-gray-100 overflow-hidden rounded-3xl border border-gray-100 text-gray-600 sm:grid-cols-2 lg:grid-cols-4 lg:divide-y-0 xl:grid-cols-4">
                    {[
                        {
                            img: one,
                            text: "Innovative product or service with a clear market opportunity",
                        },
                        {
                            img: two,
                            text: "Demonstrated evidence of customer demand and revenue growth",
                        },
                        {
                            img: three,
                            text: "Business model with potential for significant growth",
                        },
                        {
                            img: four,
                            text: "Alignment with our investment thesis and a clear path to liquidity",
                        },
                    ].map((feature, index) => (
                        <motion.div
                            key={index}
                            ref={el => (cardRefs.current[index] = el)}
                            data-index={index}
                            className="group relative bg-white transition-all duration-300 shadow-[0_0_10px_2px_rgba(58,0,77,0.4)] hover:shadow-primary"
                            initial={{ opacity: 0, y: 20 }}
                            animate={isVisible[index] ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                            transition={{ duration: 0.5, ease: 'easeInOut', delay: index * 0.2 }}
                        >
                            <div className="relative space-y-8 py-12 p-8">
                                <img src={feature.img} className="w-12" width="512" height="512" alt={`feature-${index + 1}`} />
                                <div className="space-y-2">
                                    <p className="text-gray-600">
                                        {feature.text}
                                    </p>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default Features;