import React from 'react';
import { motion } from 'framer-motion';
import Container from "./Container.jsx";
import investment from "./Assets/icons/money-growth.png";
import workspace from "./Assets/icons/shared.png";
import partnership from "./Assets/icons/handshake.png";
import mentorship from "./Assets/icons/mentorship.png";
import strategy from "./Assets/icons/strategy.png";

const WhatWeOffer = () => {
    const containerVariants = {
        hidden: { opacity: 0, transition: { staggerChildren: 0.22 } },
        visible: { opacity: 1, transition: { staggerChildren: 0.22 } },
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                type: "spring",
                stiffness: 200,
                damping: 25,
            },
        },
    };

    const titleVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: "easeOut",
            },
        },
    };

    return (
        <div id="blog">
            <Container>
                <div className="mb-12 space-y-2 text-center">
                    <motion.h2 
                        className="text-3xl font-bold text-gray-800 md:text-4xl"
                        initial="hidden"
                        whileInView="visible"
                        variants={titleVariants}
                    >
                        What We Offer
                    </motion.h2>
                </div>
                <motion.div 
                    className="flex flex-wrap gap-6 justify-center"
                    initial="hidden"
                    whileInView="visible"
                    variants={containerVariants}
                >
                    <motion.div
                        className="p-6 bg-white rounded-lg shadow-lg border border-gray-300 w-full sm:w-1/2 md:w-1/4 hover:shadow-primary hover:bg-gray-50 transition-all duration-300" 
                        variants={cardVariants}
                    > 
                        <img src={investment} className="w-12 h-12 mb-4" alt="Investment Capital" />
                        <h5 className="text-left text-lg font-semibold text-gray-900 mb-2">Substantial Investment Capital</h5>
                        <p className="text-left text-sm text-gray-600">Access significant funding opportunities to launch and grow your business effectively.</p>
                    </motion.div>
                    
                    <motion.div
                        className="p-6 bg-white rounded-lg shadow-lg border border-gray-300 w-full sm:w-1/2 md:w-1/4 hover:shadow-primary hover:bg-gray-50 transition-all duration-300" 
                        variants={cardVariants}
                    > 
                        <img src={workspace} className="w-12 h-12 mb-4" alt="Working Space" />
                        <h5 className="text-left text-lg font-semibold text-gray-900 mb-2">Working Space</h5>
                        <p className="text-left text-sm text-gray-600">Enjoy a modern workspace designed for collaboration and creativity.</p>
                    </motion.div>

                    <motion.div
                        className="p-6 bg-white rounded-lg shadow-lg border border-gray-300 w-full sm:w-1/2 md:w-1/4 hover:shadow-primary hover:bg-gray-50 transition-all duration-300" 
                        variants={cardVariants}
                    > 
                        <img src={partnership} className="w-12 h-12 mb-4" alt="Access to Partners" />
                        <h5 className="text-left text-lg font-semibold text-gray-900 mb-2">Access to Partners</h5>
                        <p className="text-left text-sm text-gray-600">Connect with industry leaders and entrepreneurs for valuable partnerships.</p>
                    </motion.div>

                    <motion.div
                        className="p-6 bg-white rounded-lg shadow-lg border border-gray-300 w-full sm:w-1/2 md:w-1/3 hover:shadow-primary hover:bg-gray-50 transition-all duration-300" 
                        variants={cardVariants}
                    > 
                        <img src={mentorship} className="w-12 h-12 mb-4" alt="Mentorship" />
                        <h5 className="text-left text-lg font-semibold text-gray-900 mb-2">Mentorship</h5>
                        <p className="text-left text-sm text-gray-600">Gain insights from experienced professionals to navigate your growth.</p>
                    </motion.div>

                    <motion.div
                        className="p-6 bg-white rounded-lg shadow-lg border border-gray-300 w-full sm:w-1/2 md:w-1/3 hover:shadow-primary hover:bg-gray-50 transition-all duration-300" 
                        variants={cardVariants}
                    > 
                        <img src={strategy} className="w-12 h-12 mb-4" alt="Support on Strategy" />
                        <h5 className="text-left text-lg font-semibold text-gray-900 mb-2">Support on Strategy</h5>
                        <p className="text-left text-sm text-gray-600">Receive tailored advice to define your vision and implement effective plans.</p>
                    </motion.div>
                </motion.div>
            </Container>
        </div>
    );
};

export default WhatWeOffer;
