import React, { useRef } from 'react';
import { FaLightbulb, FaUsers } from 'react-icons/fa'; 
import Container from '../components/Container.jsx';
import { motion } from 'framer-motion';
import { useInView } from 'framer-motion';

const AboutUs = () => {
    const ref = useRef(null);
    const inView = useInView(ref, { once: false });
    
    const containerVariants = {
        hidden: { opacity: 0, transition: { staggerChildren: 0.2 } },
        visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                type: "spring",
                stiffness: 200,
                damping: 25,
            },
        },
    };
    return (
        <div className="relative" id="home">
        <div aria-hidden="true" className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40">
            <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-primary"></div>
            <div className="blur-[106px] h-32 bg-gradient-to-r from-primary to-primary"></div>
        </div>
        <Container>
            <motion.div
                ref={ref}
                className="relative pt-36 text-center"
                initial={{ opacity: 0, y: 20 }} 
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }} 
                transition={{ duration: 1, ease: "easeInOut" }}
            >
                <h1 className="text-gray-900 font-bold text-5xl md:text-6xl xl:text-7xl">
                    About <span className="text-primary">Us</span>
                </h1>
                <p className="mt-4 text-l text-gray-600 mx-auto max-w-2xl">
                    <span className="relative inline-block">
                        <span className="absolute inset-0 bg-secondary rounded-md" />
                        <span className="relative z-10 text-3xl font-bold text-primary p-2">WELCOME</span>
                    </span>
                    &nbsp;to Aloft Incubation and Innovation Center, where ideas transform into reality. Our mission is to empower entrepreneurs, startups, and innovators by providing the resources, mentorship, and collaborative environment needed to thrive in today’s fast-paced business landscape.
                </p>
            </motion.div>
                

                <motion.div
                    className="mt-20 grid grid-cols-1 lg:grid-cols-2 gap-10"
                    initial={{ opacity: 0, y: 20 }} 
                    animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }} 
                    transition={{ duration: 0.8, ease: "easeInOut", delay: 0.2 }}
                >
                    {/* Our Vision Section */}
                    <div className="bg-white shadow-lg rounded-lg p-8 text-center lg:text-left">
                        <div className="flex justify-center lg:justify-start items-center mb-6">
                            <FaLightbulb className="text-secondary text-5xl mr-4" />
                            <h2 className="text-3xl font-bold text-gray-900">Our Vision</h2>
                        </div>
                        <p className="text-gray-600 text-l">
                            We envision a world where groundbreaking ideas flourish, and every entrepreneur has the support they need to succeed. By fostering a culture of innovation, we aim to drive economic growth and create lasting impact in our community and beyond.
                        </p>
                    </div>

                    {/* Our Community Section */}
                    <div className="bg-white shadow-lg rounded-lg p-8 text-center lg:text-left">
                        <div className="flex justify-center lg:justify-start items-center mb-6">
                            <FaUsers className="text-secondary text-5xl mr-4" />
                            <h2 className="text-3xl font-bold text-gray-900">Our Community</h2>
                        </div>
                        <p className="text-gray-600 text-l">
                            We believe that innovation thrives in a diverse and inclusive environment. Our center brings together individuals from various backgrounds, industries, and cultures, creating a rich tapestry of ideas and perspectives. Together, we celebrate creativity, resilience, and entrepreneurship.
                        </p>
                    </div>
                </motion.div>

              
                {/* What We Do Section */}
                <motion.div
                    className="mt-32 text-center"
                    initial="hidden"
                    whileInView="visible"
                    variants={containerVariants}
                >
                    <h2 className="text-3xl font-bold text-gray-900">What We Do</h2>
                    <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
                        At Aloft, we provide a range of services designed to support entrepreneurs and startups, including:
                    </p>
                    <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                        {/* Card 1 */}
                        <motion.div
                            className="p-6 bg-white rounded-lg shadow-lg border border-gray-300 text-center relative"
                            variants={cardVariants}
                        >
                            <h3 className="text-xl font-bold text-gray-900 mb-2">
                                <span className="border-b-4 border-secondary">
                                    Incubation Programs
                                </span>
                            </h3>
                            <p className="text-gray-600">
                                Our structured programs provide startups with access to essential resources, including office space, funding opportunities, and administrative support.
                            </p>
                        </motion.div>

                        {/* Card 2 */}
                        <motion.div
                            className="p-6 bg-white rounded-lg shadow-lg border border-gray-300 text-center relative"
                            variants={cardVariants}
                        >
                            <h3 className="text-xl font-bold text-gray-900 mb-2">
                                <span className="border-b-4 border-secondary">
                                    Mentorship
                                </span>
                            </h3>
                            <p className="text-gray-600">
                                We connect entrepreneurs with seasoned mentors who offer guidance, expertise, and insights that are crucial for navigating the challenges of building a successful venture.
                            </p>
                        </motion.div>

                        {/* Card 3 */}
                        <motion.div
                            className="p-6 bg-white rounded-lg shadow-lg border border-gray-300 text-center relative"
                            variants={cardVariants}
                        >
                            <h3 className="text-xl font-bold text-gray-900 mb-2">
                                <span className="border-b-4 border-secondary">
                                    Networking Opportunities
                                </span>
                            </h3>
                            <p className="text-gray-600">
                                Our community of entrepreneurs, investors, and industry experts fosters collaboration and partnership, helping you expand your network and discover new opportunities.
                            </p>
                        </motion.div>
                    </div>
                </motion.div>
            </Container>
        </div>
    );
};

export default AboutUs;