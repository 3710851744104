
import React from 'react';
import AppFooter from "../components/AppFooter.jsx";
import AppHeader from "../components/AppHeader.jsx";

const Layout = ({ children }) => {
    return (
        <>
            <head>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width" />
                <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
                <meta name="description" content="We incubate and invest in your ideas" />
                <title>Aloft Incubation</title>
                <link href="https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600;700&display=swap" rel="stylesheet" />
            </head>
            <body className="bg-white">
                <AppHeader />
                {children}
                <AppFooter />
                <div className="fixed inset-x-6 bottom-6 z-40 mx-auto w-max"></div>
            </body>
            <style>
                {`
                    html {
                        scroll-behavior: smooth;
                    }
                    body {
                        margin: 0;
                    }
                `}
            </style>
        </>
    );
};

export default Layout;