import React from 'react';
import Container from "./Container.jsx"; 
import logo from "./Assets/icons/logo-white-tagline.png";

const AppFooter = () => {
    return (
        <footer className="bg-primary rounded-lg shadow dark:bg-primary-900 m-4">
            <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div className="flex flex-col items-center sm:flex-row sm:items-center sm:justify-between">
                    <a href="" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                        <img src={logo} className="h-12" alt="Aloft Logo" />
                    </a>
                    <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white justify-center">
                        <li className="me-4 md:me-6">
                            <a href="https://www.instagram.com/aloft_centerforincubation/" className="flex items-center hover:underline">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5" viewBox="0 0 24 24">
                                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.334 3.608 1.31.975.975 1.248 2.242 1.31 3.608.058 1.266.07 1.646.07 4.85s-.012 3.584-.07 4.85c-.062 1.366-.334 2.633-1.31 3.608-.975.975-2.242 1.248-3.608 1.31-1.266.058-1.646.07-4.85.07s-3.584-.012-4.85-.07c-1.366-.062-2.633-.334-3.608-1.31-.975-.975-1.248-2.242-1.31-3.608-.058-1.266-.07-1.646-.07-4.85s.012-3.584.07-4.85c.062-1.366.334-2.633 1.31-3.608.975-.975 2.242-1.248 3.608-1.31 1.266-.058 1.646-.07 4.85-.07zm0-2.163c-3.259 0-3.667.014-4.947.072-1.281.058-2.418.27-3.29 1.142-.872.872-1.084 2.009-1.142 3.29-.058 1.28-.072 1.688-.072 4.947s.014 3.667.072 4.947c.058 1.281.27 2.418 1.142 3.29.872.872 2.009 1.084 3.29 1.142 1.28.058 1.688.072 4.947.072s3.667-.014 4.947-.072c1.281-.058 2.418-.27 3.29-1.142.872-.872 1.084-2.009 1.142-3.29.058-1.28.072-1.688.072-4.947s-.014-3.667-.072-4.947c-.058-1.281-.27-2.418-1.142-3.29-.872-.872-2.009-1.084-3.29-1.142-1.28-.058-1.688-.072-4.947-.072zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.162 6.162 6.162 6.162-2.759 6.162-6.162-2.759-6.162-6.162-6.162zm0 10.324c-2.292 0-4.162-1.87-4.162-4.162s1.87-4.162 4.162-4.162 4.162 1.87 4.162 4.162-1.87 4.162-4.162 4.162zm6.406-11.845c-.796 0-1.441.645-1.441 1.441s.645 1.441 1.441 1.441 1.441-.645 1.441-1.441-.645-1.441-1.441-1.441z"/>
                                </svg>
                                <span className="ml-1">Instagram</span>
                            </a>
                        </li>
                        <li className='me-4 md:me-6'>
                            <a href="tel:+251968545636" className="flex items-center hover:underline">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5" viewBox="0 0 24 24">
                                    <path d="M6.62 10.79a15.053 15.053 0 0 0 6.59 6.59l2.2-2.2a1.003 1.003 0 0 1 1.11-.21c1.21.49 2.52.76 3.88.76a1 1 0 0 1 1 1v3.5a1 1 0 0 1-1 1C10.29 22 2 13.71 2 3.5a1 1 0 0 1 1-1H6.5a1 1 0 0 1 1 1c0 1.36.27 2.67.76 3.88a1.003 1.003 0 0 1-.21 1.11l-2.2 2.2z"/>
                                </svg>
                                <span className="ml-1">Call Us</span>
                            </a>
                        </li>
                        <li >
                            <a href="mailto:info@aloftincubation.com" className="flex items-center hover:underline">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5" viewBox="0 0 24 24">
                                    <path d="M12 13.5l-8-5h16l-8 5zm0 2.5l8-5v7h-16v-7l8 5z"/>
                                </svg>
                                <span className="ml-1">Email Us</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <span className="block text-sm text-white sm:text-center dark:text-white">
                    Powered by <a href="https://perspective.et/" className="hover:underline">Perspective Technologies</a> © 2024
                </span>            </div>
        </footer>
    );
};

export default AppFooter;