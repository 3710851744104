import React, { useState, useEffect } from 'react';
import Container from "./Container.jsx";
import logo from "./Assets/icons/logo.png";

const AppHeader = () => {
    const [isToggled, setIsToggled] = useState(false);
    const links = [
        {
            to: "./",
            label: "Home",
        },
        {
            to: "/AboutUs",
            label: "About Us",
        },
        {
            to: "/Contact",
            label: "Contact",
        },
    ];

    const toggleNavlinks = () => {
        setIsToggled(!isToggled);
    };

    useEffect(() => {
        const navlinks = document.querySelector("#navlinks");
        const hamburger = document.querySelector("#hamburger");
        const layer = document.querySelector("#navLayer");

        if (isToggled) {
            navlinks.classList.add("!visible", "!scale-100", "!opacity-100", "!lg:translate-y-0");
            hamburger.classList.add("toggled");
            layer.classList.add("origin-top", "scale-y-100");
        } else {
            navlinks.classList.remove("!visible", "!scale-100", "!opacity-100", "!lg:translate-y-0");
            hamburger.classList.remove("toggled");
            layer.classList.remove("origin-top", "scale-y-100");
        }
    }, [isToggled]);

    return (
        <header>
            <nav className="absolute z-10 w-full border-b border-black/5 lg:border-transparent">
                <Container>
                    <div className="relative flex flex-wrap items-center justify-between gap-6 py-3 md:gap-0 md:py-4">
                        <div className="relative z-20 flex w-full justify-between md:px-0 lg:w-max">
                            <a href="/#home" aria-label="logo" className="flex items-center space-x-2">
                                <div className="flex items-center space-x-1 mb-4">
                                    <img src={logo} width="512" height="512" alt="Aloft Incubation Logo" className="h-8 w-auto" />
                                </div>
                            </a>

                            <div className="relative flex max-h-10 items-center lg:hidden">
                                <button aria-label="hamburger" id="hamburger" className="relative -mr-6 p-6" onClick={toggleNavlinks}>
                                    <div aria-hidden="true" id="line" className="m-auto h-0.5 w-5 rounded bg-sky-900 transition duration-300"></div>
                                    <div aria-hidden="true" id="line2" className="m-auto mt-2 h-0.5 w-5 rounded bg-sky-900 transition duration-300"></div>
                                </button>
                            </div>
                        </div>
                        <div id="navLayer" aria-hidden="true" className={`fixed inset-0 z-10 h-screen w-screen origin-bottom scale-y-0 bg-white/70 backdrop-blur-2xl transition duration-500 lg:hidden ${isToggled ? 'scale-y-100' : ''}`}></div>
                        <div id="navlinks" className={`invisible absolute top-full left-0 z-20 w-full origin-top-right translate-y-1 scale-90 flex-col flex-wrap justify-end gap-6 rounded-3xl border border-gray-100 bg-white p-8 opacity-0 shadow-2xl shadow-gray-600/10 transition-all duration-300 lg:visible lg:relative lg:flex lg:w-7/12 lg:translate-y-0 lg:scale-100 lg:flex-row lg:items-center lg:gap-0 lg:border-none lg:bg-transparent lg:p-0 lg:opacity-100 lg:shadow-none ${isToggled ? 'visible opacity-100 scale-100' : ''}`}>
                            <div className="w-full text-gray-600 lg:w-auto lg:pr-4 lg:pt-0">
                                <ul className="flex flex-col gap-6 tracking-wide lg:flex-row lg:gap-0 lg:text-sm">
                                    {
                                        links.map((link, index) => (
                                            <li key={index}>
                                                <a href={link.to} className="hover:text-primary block transition md:px-4">
                                                    <span>{link.label}</span>
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </nav>
        </header>
    );
};

export default AppHeader;