import React, { useState, useRef } from 'react';
import { FiUser, FiMail, FiPhone } from 'react-icons/fi';
import emailjs from 'emailjs-com';
import Container from '../components/Container.jsx';
import { motion } from 'framer-motion';
import { useInView } from 'framer-motion';

const Contact = () => {
    const ref = useRef(null);
    const inView = useInView(ref, { once: true });
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
    const [statusMessage, setStatusMessage] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const result = await emailjs.send(
                'service_mml3opg',     // service_id
                'template_rvwb1fq',    // template_id
                {
                    name: formData.name,
                    email: formData.email,
                    phone: formData.phone,
                    message: formData.message,
                },
                'JbAMvEdY5FXTwfACF'    // user_id (Public Key)
            );
    
            if (result.status === 200) {
                setStatusMessage({ text: 'Your message has been sent successfully!', type: 'success' });
            } else {
                setStatusMessage({ text: 'Failed to send message. Please try again later.', type: 'error' });
            }
        } catch (error) {
            console.error("EmailJS Error Details: ", error);
            setStatusMessage({ text: 'Error: Unable to send message at the moment.', type: 'error' });
        }
    };
    

    return (
        <div className="relative" id="home">
            <div aria-hidden="true" className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40">
                <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-primary"></div>
                <div className="blur-[106px] h-32 bg-gradient-to-r from-primary to-primary"></div>
            </div>

            <Container>
                <div ref={ref} className="pt-36 text-center">
                    <h1 className="text-gray-900 font-bold text-5xl md:text-6xl xl:text-7xl">
                        Contact<span className="text-primary"> Us!</span>
                    </h1>
                    <p className="text-gray-500">Submit your queries here and we will listen</p>
                </div>

                <motion.div
                    className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-6"
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={{
                        hidden: { opacity: 0 },
                        visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
                    }}
                >
                    <motion.div className="space-y-6" variants={{
                        hidden: { opacity: 0, y: 20 },
                        visible: { opacity: 1, y: 0, transition: { type: "spring", stiffness: 200, damping: 25 } },
                    }}>
                        <div className="bg-primary text-white rounded-lg shadow-lg p-8 hover:bg-primary-dark transition duration-300">
                            <p className="text-sm font-semibold mb-2">Call Us Directly At</p>
                            <h3 className="text-2xl font-bold mb-4">+251-968-545-636</h3>
                            <a href="tel:+251940646502">
                                <button className="bg-secondary text-primary px-6 py-2 rounded-full font-semibold hover:bg-secondary-dark transition duration-300">
                                    Call Now
                                </button>
                            </a>
                        </div>

                        <div className="bg-white border text-gray-900 rounded-lg shadow-lg p-8 hover:shadow-xl transition duration-300">
                            <p className="text-sm font-semibold mb-2">Come Visit Us!</p>
                            <h3 className="text-xl font-bold mb-4">CMC, Guji Highland Building, 3rd Floor</h3>
                            <a href="https://maps.app.goo.gl/nKPJUvLfKXq7ZqPS6" target="_blank" rel="noopener noreferrer">
                                <button className="bg-secondary text-primary px-6 py-2 rounded-full font-semibold hover:bg-secondary-dark transition duration-300">
                                    View Map
                                </button>
                            </a>
                        </div>
                    </motion.div>

                    <motion.div
                        className="bg-white border rounded-lg shadow-lg p-8"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0, transition: { type: "spring", stiffness: 200, damping: 25 } }}
                    >
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                <div>
                                    <label htmlFor="name" className="sr-only">Name</label>
                                    <div className="relative">
                                        <span className="absolute left-3 top-3 text-gray-400">
                                            <FiUser className="w-5 h-5" />
                                        </span>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            className="w-full py-3 pl-10 pr-4 border border-gray-300 rounded-full focus:ring-2 focus:ring-secondary focus:border-secondary"
                                            required
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="email" className="sr-only">Email Address</label>
                                    <div className="relative">
                                        <span className="absolute left-3 top-3 text-gray-400">
                                            <FiMail className="w-5 h-5" />
                                        </span>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Email Address"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            className="w-full py-3 pl-10 pr-4 border border-gray-300 rounded-full focus:ring-2 focus:ring-secondary focus:border-secondary"
                                            required
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="phone" className="sr-only">Phone Number</label>
                                    <div className="relative">
                                        <span className="absolute left-3 top-3 text-gray-400">
                                            <FiPhone className="w-5 h-5" />
                                        </span>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            placeholder="Phone Number"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            className="w-full py-3 pl-10 pr-4 border border-gray-300 rounded-full focus:ring-2 focus:ring-secondary focus:border-secondary"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label htmlFor="message" className="sr-only">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    placeholder="Message Here..."
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    className="w-full h-32 py-3 px-4 border border-gray-300 rounded-xl focus:ring-2 focus:ring-secondary focus:border-indigo-500"
                                    required
                                />
                            </div>

                            <button type="submit" className="py-4 px-8 bg-primary text-white rounded-full mx-auto block font-semibold hover:bg-primary-dark">
                                Send Message
                            </button>
                        </form>
                        {statusMessage && (
        <p className={`mt-4 text-center font-semibold ${statusMessage.type === 'success' ? 'text-green-500' : 'text-red-500'}`}>
            {statusMessage.text}
        </p>
    )}                    </motion.div>
                </motion.div>
            </Container>
        </div>
    );
};

export default Contact;
