import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Container from "./Container.jsx";

const CallToAction = () => {
    const ref = useRef(null);
    const [inView, setInView] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false);

    const handleInViewChange = (inView) => {
        setInView(inView);
        setIsVisible(inView);
    };

    const { ref: observerRef } = useInView({
        threshold: 0.1,
        onChange: handleInViewChange,
        triggerOnce: false,
    });

    return (
        <motion.div
            ref={observerRef}
            className="relative py-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
            transition={{ duration: 1, ease: "easeInOut" }}
        >
            <motion.div
                aria-hidden="true"
                className="absolute inset-0 h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-40"
            >
                <motion.div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-white"></motion.div>
                <motion.div className="blur-[106px] h-32 bg-gradient-to-r from-white to-primary"></motion.div>
            </motion.div>

            <Container>
                <motion.div
                    className="relative"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                >
                    <motion.div className="mt-6 m-auto space-y-6 md:w-8/12 lg:w-7/12">
                        <motion.h1
                            className="text-center text-4xl font-bold text-gray-800 md:text-5xl"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
                            transition={{ duration: 0.8, ease: "easeInOut" }}
                        >
                            Let’s <span className="playful-circle">innovate</span> together!
                        </motion.h1>

                        <motion.p
                            className="text-center text-xl text-gray-600"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
                            transition={{ duration: 1.2, ease: "easeInOut" }}
                        >
                            Whether you’re an aspiring entrepreneur, an established business looking to innovate, or an investor seeking the next big idea, aloft is here to support you. Join us on this exciting journey of discovery and growth!
                        </motion.p>

                        <motion.div
                            className="flex flex-wrap justify-center gap-6"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
                            transition={{ delay: 0.5, duration: 1, ease: "easeInOut" }}
                        >
                            <a
                                href="mailto:info@aloftincubation.com"
                                className="relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                            >
                                <span className="relative text-base font-semibold text-white">Join Us</span>
                            </a>
                        </motion.div>
                        <div className="mb-12 space-y-2 text-center">
                    <motion.h2 
                        className="text-3xl font-bold text-gray-800 md:text-xl"
        
                    >
                        info@aloftincubation.com
                    </motion.h2>
                </div>
                    </motion.div>
                </motion.div>
            </Container>
        </motion.div>
    );
};

export default CallToAction;