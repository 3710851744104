import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import './App.css';

import Layout from './layouts/Layout.jsx';
import HeroSection from './components/HeroSection.jsx';
import Features from './components/Features.jsx';
import CallToAction from './components/CallToAction.jsx';
import WhatWeOffer from './components/WhatWeOffer.jsx';
import AboutUs from './pages/AboutUs'; 
import Contact from './pages/Contact'; 


function App() {
  return (
    <Router>
      <Layout title="Welcome to Astrolus.">
        <main className="space-y-40 mb-40">
          <Routes> {/* Use Routes instead of Switch */}
            <Route path="/" element={
              <>
                <HeroSection />
                <WhatWeOffer />
                <Features />
                <CallToAction />
              </>
            } />
            <Route path="/AboutUs" element={<AboutUs />} /> 
            <Route path="/Contact" element={<Contact />} /> 

          </Routes>
        </main>
      </Layout>
    </Router>
  );
}

export default App;